// @flow
import { Cookies } from 'react-cookie';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';

import {
    LOGIN_USER,
    LOGOUT_USER,
    REGISTER_USER,
    FORGET_PASSWORD,
    RESET_PASSWORD,
    BALANCE_REQUEST,
    SIMULATE_USER, TOGGLE_ACCOUNT
} from './constants';

import {
    loginUserSuccess,
    loginUserFailed,
    registerUserSuccess,
    registerUserFailed,
    forgetPasswordSuccess,
    forgetPasswordFailed,
    resetPasswordSuccess,
    resetPasswordFailed, balanceRequestSuccess, balanceRequestFailed,
    simulateUserSuccess, simulateUserFailed, toggleAccountSuccess, toggleAccountFailed
} from './actions';

import i18n from 'i18next';
/**
 * Sets the session
 * @param {*} user
 */
export const setSession = user => {
    let cookies = new Cookies();
    if (user) {
        cookies.set('user', JSON.stringify(user), {path: '/'});
    } else {
        cookies.remove('user', { path: '/' });
    }
};
/**
 * Login the userget
 * @param {*} payload - email and password
 */
function* login({ payload: { email, password } }) {
    const options = {
        body: JSON.stringify({ email, password }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {

        setSession(null);

        const response = yield call(fetchJSON, process.env.REACT_APP_BACKEND + '/security/login', options);
        if (response.token) {
            setSession(response);
            yield put(loginUserSuccess(response));
        } else {
            yield put(loginUserFailed(i18n.t('auth.errors.unauthorized')));
        }

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put(loginUserFailed(message));
        setSession(null);
    }
}

/**
 * Logout the user
 * @param {*} param0
 */
function* logout({ payload: { history } }) {
    try {
        setSession(null);
        yield call(() => {
            history.push('/account/login');
            window.location.reload();
        });
    } catch (error) {
        console.log('ERROR LOG OUT');
    }
}

/**
 * Register the user
 */
function* register({ payload: { fullname, email, password } }) {
    const options = {
        body: JSON.stringify({ fullname, email, password }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const response = yield call(fetchJSON, '/users/register', options);
        yield put(registerUserSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put(registerUserFailed(message));
    }
}

/**
 * forget password
 */
function* forgetPassword({ payload: { email, history } }) {
    const server = process.env.REACT_APP_FRONTEND;
    const options = {
        body: JSON.stringify({ email, server }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    let message;
    yield put(forgetPasswordSuccess(''));
    try {
        const response = yield call(fetchJSON, process.env.REACT_APP_UTILS + '/request', options);
        switch (response.status) {
            case 200:
                if (response.errors) {
                    message = i18n.t(response.errors);
                } else {
                    yield put(forgetPasswordSuccess(i18n.t('auth.reset.success')));
                    yield call(() => {
                        window.resetEmail = email;
                        history.push('/account/confirm');
                    });
                    // yield put(forgetPasswordSuccess(i18n.t('auth.reset.success')));
                    return;
                }
                break;
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 404:
                message = i18n.t('auth.errors.404');
                break;
            default:
                message = response.message;
        }
        yield put(forgetPasswordFailed(message));
    } catch (error) {
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            case 404:
                message = i18n.t('auth.errors.404');
                break;
            default:
                message = error;
        }
        yield put(forgetPasswordFailed(message));
    }
}

/**
 * balance request
 */
function* balanceRequest({ payload: { identification } }) {
    const server = process.env.REACT_APP_FRONTEND;
    const options = {
        body: JSON.stringify({ identification, server }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    let message;
    yield put(balanceRequestSuccess(''));
    try {
        const response = yield call(fetchJSON, process.env.REACT_APP_UTILS + '/balance', options);
        switch (response.status) {
            case 200:
                if (response.errors) {
                    message = i18n.t(response.errors);
                } else {
                    yield put(balanceRequestSuccess(i18n.t('auth.balance.success')));
                    return;
                }
                break;
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 404:
                message = i18n.t('auth.errors.404');
                break;
            default:
                message = response.message;
        }
        yield put(balanceRequestFailed(message));
    } catch (error) {
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            case 404:
                message = i18n.t('auth.errors.404');
                break;
            default:
                message = error;
        }
        yield put(balanceRequestFailed(message));
    }
}

/**
 * reset password
 */
function* resetPassword({ payload: { password, token, history } }) {
    const options = {
        body: JSON.stringify({ password, token }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    let message;
    yield put(resetPasswordSuccess(''));
    try {
        const response = yield call(fetchJSON, process.env.REACT_APP_UTILS + '/reset', options);
        switch (response.status) {
            case 200:
                if (response.errors) {
                    message = i18n.t(response.errors);
                } else {
                    window.resetMessage = i18n.t(response.message);
                    yield call(() => {
                        history.push('/account/login');
                    });
                    return;
                }
                break;
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 404:
                message = i18n.t('auth.errors.404');
                break;
            default:
                message = response.message;
        }
        yield put(resetPasswordFailed(message));
    } catch (error) {
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            case 404:
                message = i18n.t('auth.errors.404');
                break;
            default:
                message = error;
        }
        yield put(resetPasswordFailed(message));
    }
}

/**
 * Simulate a user
 * @param {*} payload - id and token
 */
function* simulate({ payload: { id, token } }) {
    const options = {
        body: JSON.stringify({ id, token }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {

        const response = yield call(fetchJSON, process.env.REACT_APP_BACKEND + '/security/simulate', options);
        if (response.token) {

            setSession(response);
            yield put(simulateUserSuccess(response));
            window.location.reload();

        } else {

            yield put(simulateUserFailed(i18n.t('auth.errors.unauthorized')));

        }

    } catch (error) {

        let message;

        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }

        yield put(simulateUserFailed(message));

    }
}

/**
 * Toggle user current company
 * @param {*} payload - user and organization
 */
function* toggleAccount({ payload: { user, organization } }) {
    const options = {
        body: JSON.stringify({ user, organization }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {

        const response = yield call(fetchJSON, process.env.REACT_APP_BACKEND + '/security/toggle', options);
        if (response.token) {

            setSession(response);
            yield put(toggleAccountSuccess(response));
            window.location.reload();

        } else {

            yield put(toggleAccountFailed(i18n.t('auth.errors.unauthorized')));

        }

    } catch (error) {

        let message;

        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }

        yield put(toggleAccountFailed(message));

    }
}

export function* watchLoginUser(): any {
    yield takeEvery(LOGIN_USER, login);
}

export function* watchLogoutUser(): any {
    yield takeEvery(LOGOUT_USER, logout);
}

export function* watchRegisterUser(): any {
    yield takeEvery(REGISTER_USER, register);
}

export function* watchBalanceRequest(): any {
    yield takeEvery(BALANCE_REQUEST, balanceRequest);
}

export function* watchForgetPassword(): any {
    yield takeEvery(FORGET_PASSWORD, forgetPassword);
}

export function* watchResetPassword(): any {
    yield takeEvery(RESET_PASSWORD, resetPassword);
}

export function* watchSimulateUser(): any {
    yield takeEvery(SIMULATE_USER, simulate);
}

export function* watchToggleAccount(): any {
    yield takeEvery(TOGGLE_ACCOUNT, toggleAccount);
}

function* authSaga(): any {
    yield all([fork(watchLoginUser), fork(watchLogoutUser), fork(watchRegisterUser),
        fork(watchBalanceRequest), fork(watchForgetPassword), fork(watchResetPassword),
        fork(watchSimulateUser), fork(watchToggleAccount)]);
}

export default authSaga;