// @flow
import {all, call, fork, put, takeEvery} from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';

import {
    VALIDATE_PAYROLLS_FAILED,
    VALIDATE_PAYROLLS_SUCCESS, NOTIFY_PAYROLLS_FAILED, NOTIFY_PAYROLLS_SUCCESS, VALIDATE_PAYROLLS, NOTIFY_PAYROLLS
} from './constants';

import i18n from 'i18next';

function* validatePayrolls({ payload: { values, type, callback, params } }) {

    const options = {
        body: JSON.stringify({ values, type }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    let message = '';
    try {
        const response = yield call(fetchJSON, process.env.REACT_APP_BACKEND + '/payroll/validate', options);
        if (response.errors) {
            message = response.errors;
            yield put({type: VALIDATE_PAYROLLS_FAILED, error: message});
        } else {
            yield put({type: VALIDATE_PAYROLLS_SUCCESS, response: response });

            if (callback) {
                yield put({type: callback, payload: params});
            }

        }
    } catch (error) {
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put({type: VALIDATE_PAYROLLS_FAILED, error: message});
    }

}

function* notifyPayrolls({ payload: { values, callback, params } }) {

    const options = {
        body: JSON.stringify({ values }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    let message = '';
    try {
        const response = yield call(fetchJSON, process.env.REACT_APP_BACKEND + '/payroll/notify', options);
        if (response.errors) {
            message = response.errors;
            yield put({type: NOTIFY_PAYROLLS_FAILED, error: message});
        } else {
            yield put({type: NOTIFY_PAYROLLS_SUCCESS, response: response });

            if (callback) {
                yield put({type: callback, payload: params});
            }

        }
    } catch (error) {
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put({type: NOTIFY_PAYROLLS_FAILED, error: message});
    }

}

export function* watchValidatePayrollsSaga(): any {
    yield takeEvery(VALIDATE_PAYROLLS, validatePayrolls);
}

export function* watchNotifyPayrollsSaga(): any {
    yield takeEvery(NOTIFY_PAYROLLS, notifyPayrolls);
}

function* PayrollsSaga(): any {
    yield all([
        fork(watchValidatePayrollsSaga),
        fork(watchNotifyPayrollsSaga),
    ]);
}

export default PayrollsSaga;
