/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILED = 'REGISTER_USER_FAILED';
export const LOGOUT_USER = 'LOGOUT_USER';
export const BALANCE_REQUEST = 'BALANCE_REQUEST';
export const BALANCE_REQUEST_SUCCESS = 'BALANCE_REQUEST_SUCCESS';
export const BALANCE_REQUEST_FAILED = 'BALANCE_REQUEST_FAILED';
export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_FAILED = 'FORGET_PASSWORD_FAILED';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILED = 'FORGET_PASSWORD_FAILED';
export const SIMULATE_USER = 'SIMULATE_USER';
export const SIMULATE_USER_SUCCESS = 'SIMULATE_USER_SUCCESS';
export const SIMULATE_USER_FAILED = 'SIMULATE_USER_FAILED';
export const TOGGLE_ACCOUNT = 'TOGGLE_ACCOUNT';
export const TOGGLE_ACCOUNT_SUCCESS = 'TOGGLE_ACCOUNT_SUCCESS';
export const TOGGLE_ACCOUNT_FAILED = 'TOGGLE_ACCOUNT_FAILED';
